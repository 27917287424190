import { Component } from "react";
import "./index.css";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import formatDistance from "date-fns/formatDistance";

class Receivesmsverify extends Component {
  state = { smsList: [], cb: "click here to copy number", isLoading: true };
  componentDidMount() {
    this.fetchSms();
  }

  fetchSms = async () => {
    const { match } = this.props;
    const { params } = match;
    const { number } = params;

    const smsUrl = `https://api.receivesmsverify.com/webhook/${number}`;
    const options = {
      method: "GET",
      Origin: "https://receivesmsverify.com",
    };

    const userResponse = await fetch(smsUrl, options);

    if (userResponse.ok === true) {
      const userData = await userResponse.json();

      this.setState({ smsList: userData, isLoading: false });
    }
  };

  renderReceiveSms = (each) => {
    const { from, key, body, date } = each;
    const result = formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    });

    return (
      <div key={key} className="receivesms-body-card">
        <p className="receivedsms-item">{from}</p>
        <p className="receivedsms-item2">{body}</p>
        <p className="receivedsms-item">{result}</p>
      </div>
    );
  };

  clipBoard = () => {
    const { match } = this.props;
    const { params } = match;
    const { number } = params;
    this.setState({ cb: "copied" });
    navigator.clipboard.writeText(number);
  };
  renderRefresh = () => {
    document.location.reload();
  };
  render() {
    const { match } = this.props;
    const { params } = match;
    const { number } = params;
    const { smsList, cb, isLoading } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Receive SMS Online |Free Phone Number |Online SMS Verification
          </title>
          <meta
            name="description"
            content="Receive sms online and verify SMS/OTP from temp phone number for free "
          />
          <meta
            name="keywords"
            content=" receive sms online,free OTP vereification ,receive sms online usa,receive sms canada,receive free sms, temp phone number,  receive SMS UK,"
          />
        </Helmet>
        <div className="bg-receivesms-api">
          <div className="receivesms-head-card">
            <h2>Temporary Phone Number</h2>
          </div>
          <div className="receivesms-head-card">
            <h4>+{number}</h4>
            <button className="clipboardsms" onClick={this.clipBoard}>
              {cb}
            </button>
          </div>

          <div className="receivesms-head-card">
            <h2>Refresh this page to Receive SMS Online</h2>
            <button
              onClick={this.renderRefresh}
              className="smsrefresh"
              type="button"
            >
              Refresh Received Messages
            </button>
          </div>
          <div className="receivesms-sms-table-card">
            <div className="receivesms-body">
              <h3 className="receivedsms-item">From</h3>
              <h2 className="receivedsms-item2">Received SMS</h2>
              <h2 className="receivedsms-item">Time</h2>
            </div>
            {isLoading ? 
               (
              <div class="container">
                <div class="dash uno"></div>
                <div class="dash dos"></div>
                <div class="dash tres"></div>
                <div class="dash cuatro"></div>
              </div>
            ):smsList.map((each) => this.renderReceiveSms(each))
            }
          </div>

          <div className="re-sms-page">
            <p>
              Because this is a temporary mobile phone number, don't expect it
              to be active indefinitely. Some services/websites are restricted.
              Limits on the number of accounts that can be used with a single
              phone number, for example. After a few seconds, SMS/OTP messages
              sent to the above phone number will be visible. The fake number is
              almost limitless and can receive SMS from anywhere in the world.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Receivesmsverify;
