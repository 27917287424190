import { Link } from "react-router-dom";
import "./index.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img
        src="https://res.cloudinary.com/dzfehrv3n/image/upload/v1625912176/Layer_1_yfwxh7.png"
        alt="not-found"
        className="not-found-img"
      />
      <h5>Error 404 PAGE NOT FOUND</h5>
      <p>
        we’re sorry, the page you requested could not be found Please go back to
        the homepage
      </p>
      <Link to="/">
        <button type="button" className="button-style">
          Home Page
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
