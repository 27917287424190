import { Component } from "react";
import "./index.css";

class Contact extends Component {
  state = {
    uname: "",
    uemail: "",
    mess: "",
    error: "",
    nameErr: "",
    messErr: "",
  };

  onChangeName = (event) => {
    if (event.target.value.length >= 1 && event.target.value.length < 10) {
      this.setState({ uname: event.target.value, nameErr: "" });
    } else {
      this.setState({ nameErr: "min 1 to 10 characters" });
    }
  };

  onChangeEmail = (event) => {
    this.setState({ uemail: event.target.value });
  };

  onChangemess = (event) => {
    if (event.target.value.length >= 1 && event.target.value.length < 200) {
      this.setState({ mess: event.target.value, messErr: "" });
    } else {
      this.setState({ messErr: "min 1 to 200 characters" });
    }
  };

  submitForm = async (event) => {
    event.preventDefault();
    const { uname, uemail, mess } = this.state;
    const userDetails = { uname, uemail, mess };
    const url = "https://api.receivesmsverify.com/webhook/messages";
    const options = {
      method: "POST",
      
      body: JSON.stringify(userDetails),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };
    const response = await fetch(url, options);

    if (response.ok === true) {
      this.setState({ error: "Success we will contact you in 48-72 hours" });
    } else {
      this.setState({ error: response.statusText });
    }
  };

  render() {
    const { uname, uemail, mess, error,messErr,nameErr } = this.state;

    return (
      <>
        <h4 className="contact-head">Contact Form</h4>
        <form className="contact-cont" onSubmit={this.submitForm}>
          <div className="bg-form-sub-cont">
            <div className="bg-contact-name">
              <label htmlFor="name">Name*</label>
              <br />
              <input
                className="contact-input"
                type="text"
                title={nameErr}
                id="name"
                placeholder="Type your Name"
                required="required"
                value={uname}
                onChange={this.onChangeName}
              />
            </div>
            <div className="bg-contact-name">
              <label htmlFor="email">Email*</label>
              <br />
              <input
                className="contact-input"
                minlength="2"
                maxlength="40"
                type="email"
                id="email"
                placeholder="Type your Email"
                required="required"
                value={uemail}
                onChange={this.onChangeEmail}
              />
            </div>
          </div>
          <div className="bg-contact-name">
            <label htmlFor="mess">Message*</label>
            <br />
            <textarea
              id="mess"
              minlength="50"
              maxlength="500"
              required="required"
              placeholder="Your Message Here"
              rows="4"
              className="contact-input-textarea"
              value={mess}
              onChange={this.onChangemess}
            />
            <p> * These fields are required</p>
            <p className="error-field">{error}</p>
          </div>
          <div>
            <button type="submit" className="button-contact">
              Submit
            </button>
            <br />
          </div>
        </form>
      </>
    );
  }
}

export default Contact;
