import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import formatDistance from "date-fns/formatDistance";
import Footer from "../Footer";
import "./index.css";

let phNumbers = [
  {
    id: 1,
    number: "18192017230",
    country: "canada",
  },
];

const Canada = () => {
  const renderUsa = (each) => {
    const { id, number } = each;

    const result = formatDistance(new Date(2022, 7, 6), new Date(), {
      addSuffix: true,
    });

    return (
      <Link to={`/receivesms/${number}`} className="linkstyle">
        <div key={id} className="usa-num-head2">
          <p className="usa-num-margin2">{number}</p>
          <p className="usa-num-margin2">{result}</p>
          <p className="usa-num-margin2">SMS Text verify Here</p>
        </div>
      </Link>
    );
  };

  return (
    <>
      <Helmet>
        <title>
        Receive sms online| CANADA temp Ph numbers| verify OTP/SMS
        </title>
        <meta
          name="description"
          content="Receive sms online from canada temporary phone numbers and verify OTP/SMS messages for free.Online SMS verification done easy "
        />
        <meta
          name="keywords"
          content="receive sms online,receive messages ,receive sms online CANADA,receive sms CANADA,receive free sms,temporary phone number,verify sms free"
        />
        <link rel="canonical" href="https://receivesmsverify.com/usa" />
      </Helmet>
      <div className="bg-receive-sms-usa">
        <h2 className="usa-howto-receive">How to receive sms ?</h2>
        <ol className="list-howto">
          <li> Choose a CANADA temporary phone number below and click read</li>
          <li>
            {" "}
            Send sms to a temporary phone number below. Receive sms online and
            text verify sms free
          </li>
        </ol>

        <div className="usa-num-table-card">
          <p>CANADA Temp numbers List</p>

          <div className="usa-num-head">
            <h2 className="usa-num-margin">CANADA Temp Number </h2>
            <h2 className="usa-num-margin">Added</h2>
            <h2 className="usa-num-margin">Read SMS</h2>
          </div>

          {phNumbers.map((each) => renderUsa(each))}
        </div>
        <div className="uk-sms-page-note">
          <h3>Note about Temp numbers usage</h3>
          <p>
            These are transient or, more commonly, temporary phone numbers. This
            indicates that they are only accessible for a limited time. Some
            phone lines are active for several months while others are only
            active for a short while. Please refrain from texting sensitive
            information, such as passwords or private messages to pals, or using
            a temporary number for website phone verification. The text messages
            sent to these phone numbers could be accessed by anyone, giving them
            access to your account. There is typically a maximum number of times
            you can use a phone number on websites that demand phone number
            verification. In this situation, it is possible that certain of our
            phone numbers will be restricted. However, this is not a serious
            issue.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Canada;
