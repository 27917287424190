import "./index.css";

const Faq = () => {
  return (
    <div className="terms">
      <h1 className="FaqHead">Frequently Asked Questions</h1>
      <h2>How much does using this numbers cost?</h2>
      <p>
        We think this is a very wonderful service. We never charge our customers
        for it. And we guarantee that service will never cost a thing to the
        users.{" "}
      </p>
      <h2>How often do you add a new number in your website?</h2>
      <p>
        We add a new number every week or monthly. However, we don't know
        exactly when we add the number{" "}
      </p>
      <h2>Does receivesmsverify.com need your phone number? </h2>
      <p>No, you don't need a phone number</p>
      <h2> How many temp numbers do you create for each country? </h2>
      <p>
        We have a few numbers, and we might delete some invalid ones.we will add
        more soon
      </p>
      Do you block messages ? No, we do not block or filter any messages that is
      received.Any message will be received by our numbers.
      <h2>Can I receive text/OTP message using receivesmsverify.com?</h2>
      <p>
        Yes, You can receive SMS/OTP using Receivesmsverify.com. only US and
        Canada and UK are available.
      </p>
      <h2>can any one use this site?</h2>
      <p>
        It is intended and developed for web developers but not for illegal use.
        Any illegal activity is prohibited
      </p>
      <h2>Do you send sms?</h2>
      <p>
        we dont send any messages.we provide only inbound service from Temp
        Number{" "}
      </p>
    </div>
  );
};

export default Faq;
