import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import Faq from "../Faq";

import "./index.css";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>
        Receive SMS Online |Free Phone Number |Online SMS Verification
        </title>
        <meta
          name="description"
          content="Receivesmsverify.com is free to verify SMS&OTP online from our Free Temporary Phone Numbers updated regularly to receive sms online.You can use online sms receiver to verify OTP Messages from various websites for free."
        />
        <meta
          name="keywords"
          content="
receive sms online,verify otp online,receive otp online,free temp number,online sms receiver, receive messages online,temporary phone number,free message receiver"
        />
        <link rel="canonical" href="https://receivesmsverify.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="country | Temporary phone number | Receive SMS online"
        />
        <meta
          property="og:description"
          content="ReceiveSMSverify.com is a free SMS/OTP verification service using virtual numbers "
        />
        <meta property="og:url" content="https://receivesmsverify.com/" />
        <meta
          property="og:site_name"
          content="Temporary phone number | Receive SMS online"
        />
        <meta
          property="article:modified_time"
          content="2022-10-25T12:07:36+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="bg-card">
        <div className="bg-card-head-content">
          <h2 className="main-head">
            Receive Sms Online For Free with Our Temp Number
          </h2>
          <p className="sub-tag">
            Receivesmsverify.com is free online service to receive sms online to
            verify sms online for developers.Feel Free to use our service to
            receive free SMS verification from Temporary phone Numbers updated
            regularly to receive sms all over the world to our temp numbers Not
            for illegal use. Our temp numbers can receive sms online globally
            even if the sms is from a different country.All the temp numbers are
            disposable numbers and all the received sms are discarded.
          </p>
        </div>
        <p className="flashit">New Numbers Added</p>
        <div className="countries-card">
          <div className="country-sub-card">
            <h3 className="country-head">Country</h3>
            <h2 className="number-head">Temp Number</h2>
          </div>
          <div className="country-names-card">
            <div className="country-name-sub-head">
              <p>
                <Link to="/usa" className="linkstyle">
                
                  USA
                </Link>
              </p>

              <p>
                <Link to="/uk" className="linkstyle">
                  UK
                </Link>
              </p>
              <p>
                <Link to="/canada" className="linkstyle">
                  CANADA
                </Link>
              </p>
            </div>
            <div className="numbers-name-sub-head">
              <p>2</p>
              <p>1</p>
              <p>1</p>
            </div>
            
          </div>
          
          <p className="country-sub-para">
            Free Temp numbers from different countries online to receive sms
            online
          </p>
          
        </div>
        <div className="about-home-card">
          <h3 className="about-home">About Our service</h3>
          <p className="about-home-para">
            Receiving SMS online is a great way to keep your personal
            information secure and verified. Often, online verification requires
            an SMS code or number to be sent to you in order to complete the
            process. With Receivesmsverify.com, you can easily receive SMS
            online from any website that requires an OTP or SMS verification
            code. We provide a free and easy-to-use online SMS receiver that is
            updated regularly with new temporary phone numbers for you to use
            when verifying your sms online.
          </p>
        </div>
        <div className="about-service-home">
          <p className="about-service-home-para">
            Receive SMS Online and get text verified on Twitter, Facebook,
            Instagram, Telegram, Whatsapp, Linkedin, VK, UBER, eBay, Viber and
            many more with our temporary phone numbers for free online for
            developers.Not for illegal use Receive sms online and verify from
            our temp numbers from USA, Germany, United Kingdom, Canada,Thailand
            etc. We bring more temp Numbers Soon..{" "}
          </p>

          <div>
            <h4 className="about-home">Features</h4>
            <div className="feature">
              <div className="fe-sub-cont">
                <img
                  className="temp-num-img"
                  src="https://images.pexels.com/photos/248526/pexels-photo-248526.jpeg?auto=compress&cs=tinysrgb&w=100&h=100&dpr=1"
                  alt="receive sms online"
                />
                <h4>Temp Number</h4>
                <p className="feature-para-format">
                  These are temporary phone numbers that can be disposed.
                  They're perfect for those times when you need to keep your
                  personal number private, or for when you're traveling and
                  don't want to give out your regular number. New numbers are
                  allocated on a regular basis, so you can always have a fresh
                  one to use.
                </p>
              </div>
              <div className="fe-sub-cont">
                <img
                  className="temp-num-img"
                  src="https://images.pexels.com/photos/164425/pexels-photo-164425.jpeg?auto=compress&cs=tinysrgb&w=100&h=100&dpr=1"
                  alt="receive sms online"
                />
                <h4>SMS Privacy </h4>
                <p className="feature-para-format">
                  privacy is important for every one and our service keep your
                  contact information private while receiving sms online from
                  sms service providers.We also do not disclose any of this
                  information unless we get permission.
                </p>
              </div>
              <div className="fe-sub-cont">
                <img
                  className="temp-num-img"
                  src="https://images.pexels.com/photos/267389/pexels-photo-267389.jpeg?auto=compress&cs=tinysrgb&w=100&h=100&dpr=1"
                  alt="receive sms online"
                />
                <h4>Free To Use SMS Service</h4>
                <p className="feature-para-format">
                  People are always going online to receive as many SMS for free
                  and without charging money for receiving SMS. They can easily
                  do this by sending as many messages as they like to activate
                  accounts that otherwise require personal information such as
                  login details to verify.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Faq />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
