import { Link } from "react-router-dom";

import "./index.css";

const Footer = () => {
  return (
    <footer className="bg-foot">
     
     <div className="bg-cont-footer">
      <Link to="terms" className="foot-link">
        <p>Privacy Policy</p>
      </Link>
      <Link to="contactus" className="foot-link">
        <p>Contact Us</p>
      </Link>
      </div>
      <p>Receivesmsverify.com © 2022</p>
    </footer>
  );
};

export default Footer;
