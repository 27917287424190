import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Component/Home";
import Usa from "./Component/Usa";
import Header from "./Component/Header";
import Canada from "./Component/Canada";
import Uk from "./Component/Uk";
import Terms from "./Component/Terms";
import NotFound from "./Component/Notfound";
import Receivesmsverify from "./Component/Receivesmsverify";
import Faq from "./Component/Faq";
import Contact from "./Component/ContactUs";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/usa" component={Usa} />
        <Route exact path="/uk" component={Uk} />
        <Route exact path="/canada" component={Canada} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/contactus" component={Contact} />
        <Route exact path="/receivesms/:number" component={Receivesmsverify} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
